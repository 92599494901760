<template>
  <div class="dashboard">
    <h1>Admin Dashboard</h1>
    <BlogManager />
    <button @click="logout">Logout</button>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import BlogManager from "@/components/BlogManager.vue";

export default {
  components: {
    BlogManager,
  },
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.push("/admin-login");
      });
    },
  },
};
</script>

<style scoped>
.dashboard {
  text-align: center;
}
button {
  margin-top: 20px;
  padding: 10px 20px;
  background: blue;
  color: white;
  border: none;
  cursor: pointer;
}
</style>
