<template>
  <div>
    <h2>Manage Blog Posts</h2>
    <form @submit.prevent="addPost">
      <input type="text" v-model="title" placeholder="Title" required />
      <textarea v-model="content" placeholder="Content" required></textarea>
      <input type="file" @change="handleFileUpload" required/>
      <button type="submit">Add Post</button>
    </form>
    <ul>
      <li v-for="post in posts" :key="post.id">
        <h3>{{ post.title }}</h3>
        <p>{{ post.content }}</p>
        <button @click="deletePost(post.id)">Delete</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { auth, db } from "@/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, getDocs, deleteDoc, doc, Timestamp } from "firebase/firestore";

export default {
  data() {
    return {
      title: "",
      content: "",
      posts: [],
      email: null,
      imageFile: null,
    };
  },
  async created() {
    try {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.email = user.email;
        } else {
          this.email = null;
        }
      });
      console.log(this.email)
      const querySnapshot = await getDocs(collection(db, "blogPosts"));
      this.posts = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  },
  methods: {
    async addPost() {
      try {
        const docRef = await addDoc(collection(db, "blogPosts"), {
          title: this.title,
          content: this.content,
          createdAt: Timestamp.now(),
          author: this.email
        });
        this.posts.push({ id: docRef.id, title: this.title, content: this.content });
        this.title = "";
        this.content = "";
      } catch (error) {
        console.error("Error adding post:", error);
      }
    },
    async deletePost(id) {
      try {
        await deleteDoc(doc(db, "blogPosts", id));
        this.posts = this.posts.filter((post) => post.id !== id);
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    },
  },
};
</script>

<style scoped>
form {
  margin-bottom: 20px;
}
input, textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
button {
  margin-top: 10px;
}
</style>