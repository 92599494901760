<template>
    <div class="blog-form">
      <h2>{{ isEditMode ? 'Edit Post' : 'New Post' }}</h2>
      <form @submit.prevent="handleSubmit">
        <input
          type="text"
          v-model="title"
          placeholder="Post Title"
          required
        />
        <textarea
          v-model="content"
          placeholder="Write your content here..."
          required
        ></textarea>
        <input type="file" @change="handleFileUpload" />
        <button type="submit">{{ isEditMode ? 'Save Changes' : 'Create Post' }}</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      post: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        title: this.post ? this.post.title : '',
        content: this.post ? this.post.content : '',
        imageFile: null,
        isEditMode: !!this.post,
      };
    },
    methods: {
      handleFileUpload(event) {
        this.imageFile = event.target.files[0];
      },
      handleSubmit() {
        const newPost = {
          title: this.title,
          content: this.content,
          image: this.imageFile,
        };
        this.$emit('submit', newPost);
      },
    },
  };
  </script>
  
  <style scoped>
  .blog-form {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }
  
  button {
    padding: 10px 20px;
  }
  </style>