<template>
  <nav class="navbar">
    <div class="navbar-left">
      <input
        type="text"
        v-if="!isHomePage"
        v-model="searchQuery"
        class="search-input"
        placeholder="Search"
        @input="searchModels"
      /> 
    </div>

    <div class="navbar-right" @click="toggleMenu">
      <div :class="['menu-icon', { open: menuOpen }]">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <transition name="menu-slide">
      <div v-if="menuOpen" class="fullscreen-menu">
        <div class="menu-header">
          <i class="fas fa-times close-icon" @click="closeMenu"></i>
        </div>
        
        <!-- Масштабируем и уплотняем элементы меню через inline-стили -->
        <ul class="menu-list" 
            style="transform: scale(1.3); transform-origin:center center; margin:0; padding:0; line-height:0.8;">
          
          <li @click="navigateTo('/')">
            <router-link to="/" class="nav-link">News</router-link>
          </li>
          <li @click="navigateTo('/models')">
            <router-link to="/models" class="nav-link">Models</router-link>
          </li>
          <li>
            <a href="https://www.instagram.com/saintfacesagency?igsh=N2tpYjVxZmh4YzB1" 
               target="_blank" 
               rel="noopener" 
               class="nav-link">Instagram</a>
          </li>
          <li @click="navigateTo('/be-discovered')">
            <router-link to="/be-discovered" class="nav-link">Be Discovered</router-link>
          </li>
          <li @click="navigateTo('/contact')">
            <router-link to="/contact" class="nav-link">Contacts</router-link>
          </li>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      searchQuery: "", 
    };
  },
  computed: {
    isHomePage() {
      return this.$route.path === '/';
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    navigateTo(route) {
      this.menuOpen = false;
      this.$router.push(route);
    },
    searchModels() {
      this.$router.push({ path: "/models", query: { search: this.searchQuery } });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'MillerBannerLight';
  src: url('@/assets/fonts/Miller-Banner-Light-01.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.nav-link {
  font-family: 'MillerBannerLight', serif;
  font-weight: 300;
  font-size: 50px; 
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
  color: rgb(235, 231, 231);
  transition: color 0.5s, transform 0.5s; /* Замедляем анимацию до 0.5s */
  display: inline-block;
}

.nav-link:hover {
  color: #626060;
  transform: scale(1.1);
}

/* Обновлённые стили для трёх полос в меню */
.menu-icon {
  width: 30px; 
  height: 22px; /* Увеличенная высота для размещения трёх линий */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Равномерное распределение линий */
  cursor: pointer;
  transition: transform 0.5s ease;
  gap: 4px; /* Промежуток между линиями */
}

.menu-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.menu-icon span {
  display: block;
  height: 2px;
  background-color: black;
  border-radius: 1px;
  transition: all 0.3s ease;
}

/* Анимация появления меню */
.menu-slide-enter-active, .menu-slide-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.menu-slide-enter, .menu-slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.navbar {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  z-index: 1000;
}

.navbar-right {
  margin-right: 20px; 
}

.search-input {
  width: 100px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid rgb(5, 5, 5);
  padding-left: 10px;
  
  /* Плавная анимация изменения рамки при фокусе */
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #666;
}

.video-container {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  z-index: -1; 
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(10, 10, 10, 0.8); /* Уменьшенная непрозрачность */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  text-align: center;
  transition: background-color 0.5s ease; /* Плавный переход */
}

.menu-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-icon {
  font-size: 30px;
  color: rgb(245, 240, 240);
  cursor: pointer;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fullscreen-menu ul {
  list-style: none;
  padding: 0;
}

.fullscreen-menu li {
  margin: 10px 0; 
}

.menu-slide-enter-active, .menu-slide-leave-active {
  transition: transform 0.7s ease, opacity 0.7s ease;
}

.menu-slide-enter, .menu-slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* Мобильная версия */
@media (max-width: 767px) {
  .nav-link {
    font-size: 32px; /* Уменьшили размер шрифта */
    letter-spacing: 0.2px; /* Уменьшили межбуквенное расстояние */
  }

  .fullscreen-menu .menu-list {
    transform: scale(1.0);
    line-height: 1.5; /* Увеличили межстрочное расстояние для лучшей читаемости */
    overflow-y: auto; /* Добавили прокрутку, если список всё ещё не помещается */
    padding: 10px 20px; /* Добавили отступы для аккуратного вида */
  }

  .fullscreen-menu li {
    margin: 8px 0; /* Уменьшили отступы между пунктами меню */
  }

  .menu-header {
    top: 15px;
    right: 15px;
  }

  .close-icon {
    font-size: 24px; /* Уменьшили размер значка закрытия */
  }

  .menu-icon {
    width: 25px; /* Уменьшили размер иконки меню */
    height: 18px; /* Корректировка высоты для трёх линий */
    gap: 2px; /* Уменьшили промежуток между линиями */
    transition: transform 0.3s ease;
  }

  .menu-icon span {
    height: 1.5px; /* Тоньше линии для мобильных устройств */
    background-color: #000; /* Цвет линий */
  }

  .search-input {
  width: 100px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid rgb(5, 5, 5);
  padding-left: 10px;
}
}

@media (max-width: 480px) {
  .menu-icon {
    width: 20px; /* Дополнительное уменьшение размера иконки */
    height: 14px;
    gap: 1px; /* Ещё меньше промежуток между линиями */
    transition: transform 0.2s ease;
  }

  .menu-icon span {
    height: 1px; /* Ещё тоньше линии */
  }

  .nav-link {
    font-size: 28px; /* Ещё меньше размер шрифта */
  }

  .close-icon {
    font-size: 20px; /* Дополнительное уменьшение размера */
  }

  .fullscreen-menu .menu-list {
    padding: 8px 16px; /* Немного уменьшенные отступы */
  }

  .search-input {
    width: 50%; /* Ещё более узкое поле поиска */
    height: 25px;
    font-size: 12px;
    padding: 4px 8px; 
    border-radius: 12px;
  }
}
</style>
